<template>
    <span>
        <status-chip
            v-if="hasAIFeatures"
            status="AmpCast Distribution"
            class="mr-1"
            icon-only
        >
            <template #icon>
                <v-icon class="mx-n1">$vuetify.icons.sparkle</v-icon>
            </template>
        </status-chip>

        <status-chip
            v-if="isFirstRecurring"
            status="First Recurring Request"
            label="1"
            class="mr-1 font-weight-black text-button"
        />

        <status-chip
            v-if="isLimitedDistribution"
            status="Selective Distribution"
            label="SD"
            color="turquoise"
            text-color="white"
            class="mr-1"
            dense
        />

        <status-chip
            v-if="isRecurring"
            status="Recurring"
            :tooltip="recurringTooltipCombined"
            color="transparent"
            icon-only
        >
            <template #icon>
                <v-icon small color="primary-color">rotate</v-icon>
            </template>
        </status-chip>

        <status-chip
            v-if="isPremium"
            status="Premium Distribution"
            color="transparent"
            icon-only
        >
            <template #icon>
                <v-icon small color="turquoise">gem</v-icon>
            </template>
        </status-chip>

        <status-chip
            v-if="isFinancial"
            status="Financial Distribution"
            color="transparent"
            icon-only
        >
            <template #icon>
                <v-icon small color="purple lighten-1">
                    fa-comment-dollar
                </v-icon>
            </template>
        </status-chip>

        <status-chip
            v-if="isMsn"
            status="MSN Distribution"
            color="transparent"
            icon-only
        >
            <template #icon>
                <v-icon small color="msn-blue">$vuetify.icons.msn</v-icon>
            </template>
        </status-chip>

        <status-chip
            v-if="isOverdue"
            :tooltip="overdueTooltip"
            color="transparent"
            icon-only
        >
            <template #icon>
                <v-icon small color="error">far fa-clock</v-icon>
            </template>
        </status-chip>
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { formatDate, formatDateTime } from '@/utils/helpers';

import { StatusChip } from '@/components/StatusChip';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { Assignment } from '@/types/Assignment';

const AuthorRequestTypeChipProps = Vue.extend({
    name: 'AuthorRequestTypeChip',
    props: {
        request: {
            type: Object as PropType<Partial<AuthorRequest> | null>,
            default(): AuthorRequest | null {
                return null;
            }
        },
        assignment: {
            type: Object as PropType<Partial<Assignment> | null>,
            default(): Assignment | null {
                return null;
            }
        }
    }
});

@Component({
    components: {
        StatusChip
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AuthorRequestTypeChip extends AuthorRequestTypeChipProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    assignmentNumber = 1;

    get isLimitedDistribution() {
        return this.assignment?.limited_distribution_only ?? false;
    }

    get isPremium() {
        return this.assignment?.announcement?.is_premium ?? false;
    }

    get isFinancial() {
        return this.assignment?.announcement?.is_financial ?? false;
    }

    get isMsn() {
        return this.assignment?.announcement?.is_msn ?? false;
    }

    get isRecurring() {
        return !this.request?.rejected_time && this.request?.frequency !== null;
    }

    get recurringTooltip() {
        if (this.request?.paused_at) {
            return 'Recurring Request';
        }

        return this.request?.latest_assignment
            ? `Recurring Request - Next Amp on ${this.nextAmpAt}`
            : 'Recurring Request - Not Submitted';
    }

    get recurringTooltipCombined() {
        return [this.recurringTooltip, this.assignmentNumberString].join(
            '<br/>'
        );
    }

    get assignmentNumberString() {
        return `#${this.assignmentNumber} in request`;
    }

    get isFirstRecurring() {
        return this.assignment
            ? this.assignment.is_first_recurring
            : this.request?.latest_assignment?.is_first_recurring;
    }

    get isOverdue() {
        return (this.isAuthor || this.isEditor) && this.assignment?.overdue;
    }

    get overdueTooltip() {
        return this.request?.latest_assignment?.created
            ? `The latest assignment for this amp is overdue. Created on ${formatDate(
                  this.request.latest_assignment.created
              )}`
            : '';
    }

    get hasAIFeatures() {
        return Boolean(this.request?.user?.has_ai_features);
    }

    get nextAmpAt() {
        if (
            this.request?.latest_assignment &&
            this.request?.requested_time &&
            this.request?.frequency
        ) {
            if (
                this.request.latest_assignment.created >
                this.request.requested_time
            ) {
                return this.nextAmpAtDate(
                    this.request.latest_assignment.created,
                    this.request.frequency
                );
            }

            return this.nextAmpAtDate(
                this.request.requested_time,
                this.request.frequency
            );
        }

        return '';
    }

    get assignmentId() {
        return this.assignment
            ? this.assignment.id
            : this.request?.latest_assignment?.id;
    }

    nextAmpAtDate(date: number, frequency: number) {
        return formatDateTime(
            this.$date.unix(date).add(frequency, 'seconds').unix()
        );
    }

    mounted() {
        this.getAssignmentNumber();
    }

    getAssignmentNumber() {
        if (this.assignmentId && this.isRecurring) {
            this.$http
                .get(`/author_requests/assignment_number/${this.assignmentId}`)
                .then(({ data }) => {
                    if (data?.data?.assignment_number) {
                        this.assignmentNumber = data.data.assignment_number;
                    }
                })
                .catch(() => {
                    /* silently */
                });
        }
    }
}
</script>
